import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const FilterContainer = styled.section`
`;

export const Filters = styled.section`
  ${breakpoint('tablet')`
     display: flex;
  `};
`;

export const FilterItemContainer = styled.div`
  ${breakpoint('tablet')`
     margin-right: 30px;
  `};
`;

export const IndividualFilter = styled.div`
  margin-top: 10px;
`;

export const FilterButton = styled.button`
  padding: 0 10px !important;
  border: 1px solid #CCC;
  height: 45px;
  cursor: pointer;
  
  svg {
   width: auto !important;
  }
  
  svg g {
      fill: #000;
    }
  
  ${({ active }) => active && `
    border: 1px solid #000;
    
    svg g, svg g path {
      fill: #FFF !important;
    }
  `}
  
  ${({ color }) => color && `
    color: ${color}
    border: 2px solid ${color}
  `}
  
  ${({ active, color }) => active && color && `
    background: ${color}
    color: #FFF;
  `}
 
`;

export const Results = styled.section`
  ${breakpoint('tablet')`
     margin: 15px 0;
  `};
`;

export const Label = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const FiltersContainer = styled.div`
  flex: 1 0;
`;

export const SearchContainer = styled.span`
  width: 300px;
  padding-right: 15px;
`;

export const SearchBox = styled.input`
  font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
  border: 1px solid #c6c6c6;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
`;
