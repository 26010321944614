import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const LightMenuTheme = {
  iconColour: '#FFF',
  logoColour: '#FFF',
};

export const DarkMenuTheme = {
  iconColour: '#000',
  logoColour: '#98BE46',
};

export const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  padding: 30px 30px;
  
  ${breakpoint('tablet')`
    //position: absolute;
  `};
`;

export const CentralHeaderItem = styled.div`
  display: flex;
  flex: 1 1;
  justify-content: center;
  z-index: 19;
  cursor: pointer;
`;

export const SideMenuItem = styled.div`
  display: flex;
  flex: 1;
  width: 100px;  
  z-index: ${props => (props.direction === 'left' ? 19 : 17)};
  justify-content: ${props => (props.direction === 'left' ? 'flex-start' : 'flex-end')};
  cursor: pointer;
  
  svg {
    width: 20px;
  }
  
  svg g {
    fill: ${props => props.theme.iconColour};
    
    ${({ isDark }) => isDark && `
        fill: #000 !important;
    `}
  }
  
  p {
    ${({ isDark }) => isDark && `
      color: #000 !important;
    `}
  }
`;

export const MenuButton = styled.div`
  z-index: 18;

  ${breakpoint('tablet')`
    display: flex;
  `};
  svg {
	  margin-top: 2px;
  }
  svg g {
    fill: ${props => props.theme.logoColour};
  }

    ${({ isDark }) => isDark && `
      svg g {
        fill: #98BE46
      }
      
      p {
        color: #000000 !important;
      }   
    `}

    ${({ forceWhite }) => forceWhite && `
      svg g {
        fill: #FFFFFF !important;
      }    
    `}
`;

export const HeaderLogoMobile = styled.div`
  z-index: 18;

  ${breakpoint('mobile')`
    display: flex;
  `};
  
  ${breakpoint('desktop')`
    display: none;
  `};

  svg g, svg path {
    fill: ${props => props.theme.logoColour};
  }

    ${({ isDark }) => isDark && `
      svg g {
        fill: #98BE46
      }
      
      p {
        color: #000000 !important;
      }   
    `}

    ${({ forceWhite }) => forceWhite && `
      svg g {
        fill: #FFFFFF !important;
      }    
    `}
`;

export const HeaderLogoDesktop = styled.div`
  z-index: 18;
  height: 25px;

  ${breakpoint('mobile')`
    display: none;
  `};
  
  ${breakpoint('desktop')`
    display: flex;
  `};

  svg g, svg path {
    fill: ${props => props.theme.logoColour};
  }

    ${({ isDark }) => isDark && `
      svg g, svg path {
        fill: #98BE46
      }
      
      p {
        color: #000000 !important;
      }   
    `}

    ${({ forceWhite }) => forceWhite && `
      svg g, svg path {
        fill: #FFFFFF !important;
      }    
    `}
`;

export const MenuLabel = styled.p`
  color: #FFF;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 13px;
  display: none;
  margin: 0 15px;
  
  ${breakpoint('tablet')`
    display: inline-block;
  `};
`;


export const BackToAll = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	height: 86px;
	padding: 30px;
	z-index: 99;

	svg {
		display: block;
		margin-right: 10px;
		margin-top: -1px;
	}

	p {
		display: none;
		font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
		font-weight: 300;
		font-size: 13px;
		letter-spacing: 3px;
		text-transform: uppercase;

		${breakpoint('tablet')`
			display: inline-block;
		`};
	}
`;