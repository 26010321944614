import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import withReveal from 'react-reveal/withReveal'
import { Fade } from 'react-reveal'
import React from 'react'
import { DESKTOP_MAX_WIDTH } from './variables'

export const ContactFormContainer = styled.section`
  ${breakpoint('tablet')`
    flex: 1;
    padding: 50px;
    
    button {
      float:left;
    }
  `};
`

export const MapImage = styled.img`
  width: 100%;
  max-width: 100%;
`

export const Map = styled.div`
  padding: 50px;

  svg {
    width: 100%;
  }
`

export const Container = styled.section`
  margin: 0 30px 30px;

  ${breakpoint('tablet')`
     max-width: 1100px;
     margin: 0 auto;
     display: flex;
  `};
`

export const RegionalOfficesContainer = styled.section`
  width: 100%;

  ${breakpoint('tablet')`
    width: 50%;
    padding-left: 40px;
    padding: 50px;
    
    
    h2 {
      margin-left: 20px;
    }
  `};
`

export const RegionalSubOffices = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${breakpoint('tablet')`
		grid-template-columns: 1fr 1fr;
	`};
`

export const DistributionContainer = styled.section`
  padding: 20px 30px;
  background-color: #f0f0f0f0;
`

export const InnerDistributionContainer = styled.section`
  ${breakpoint('tablet')`
     max-width: ${DESKTOP_MAX_WIDTH};
     margin: 40px auto;
     text-align: center;
     
     h1 {
        margin: 20px 0;
     }
     
     p {
        max-width: 1000px;
        margin: 0 auto;
     }
     
     article {
        flex: 1;
        margin: auto;
        margin: 0 15px;
     }
  `};
`

export const Centers = styled.section`
  ${breakpoint('tablet')`
    display: flex;
    justify-content: center;
    margin: 0 -15px;
    text-align: left !important;
  `};
`

export const CentersGrid = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  row-gap: 20px;
`

export const CentersChild = styled.div`
  article {
    margin: 0 5px !important;
  }

  .react-reveal {
    height: 100%;
  }
`

export const DropdownOuter = styled.span`
  ${breakpoint('tablet')`
    position: relative;
    max-width: 300px;
    margin: 40px auto;
    display: block;
  `};
`

export const InlineDropdownOuter = styled.div`
  position: relative;
  max-width: 300px;
  margin: 10px;
  display: flex;
  align-items: center;
`

export const DropdownFlag = styled.span``

export const WhiteDropdown = styled.select`
  border-radius: 4px;
  background: 0;
  width: 150px;
  padding: 10px;
  color: #fff;
  display: block;

  option {
    color: #000 !important;
  }
`

export const WhiteDropdownWrapper = styled.div`
  position: relative;

  &:after {
    content: ' ';
    background: url('/images/dropDownWhite.svg') no-repeat center;
    ${breakpoint('tablet')`
      background: none;
    `};
    width: 10px;
    height: 10px;
    background-size: cover;
    position: absolute;
    top: 50%;
    right: 13px;
    margin-top: -5px;
    pointer-events: none;
  }
`
