import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';

export const FormContainer = styled.div`
  overflow: hidden;
  color: #FFFFFF;
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  max-width: 350px;
  padding-right: 20px;
  font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
`;

export const InnerContainer = styled.form`
  max-width: 500px;
  margin: 0 auto;
`;

export const InputContainer = styled.div`
  display: flex;
`;


export const Button = styled.button`
  font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
  font-size: 14px;
  letter-spacing: 2px;
  padding: 5px 20px;
  margin: 10px;
  color: #000000;
  background-color: #FFFFFF;
  text-transform: uppercase;
  cursor: pointer;
`;



export const FormTitle = styled.div`
  margin: 0;
  margin-bottom: 10px;
`;

export const FormPrivacy = styled.label`
  cursor: pointer;
  margin-top: 5px;
  display: flex;

  input {
    margin-right: 15px;
  }

  p{
    color: #FFFFFF;
    text-align: left;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    max-width: 350px;
    margin: 0;
    font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
  }
`;

export const TextInput = styled.input`
  display: block;
  margin: 10px 0;
  padding: 10px 8px;
  width: 100%;
  border: 1px solid #CCC;
  border-radius: 4px;
  background: #F0F0F0;
  font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
  color: #333333;
`;


export const ErrorMessage = styled.p`
  color: red;
`;