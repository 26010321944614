import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { navigate } from 'gatsby'
import { Location } from '@reach/router'
import { HamburgerSqueeze } from 'react-animated-burgers'
import Overlay from '../overlay/overlay'

import {
  MenuItemsContainer,
  MenuItem,
  BackgroundImage,
  BackgroundImageContainer,
} from './styles'

import { MenuButton, MenuLabel } from '../header/styles'
import { isWide } from '../../utils/responsive'

class Menu extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.onMenuItemClicked = this.onMenuItemClicked.bind(this)
    this.onMenuToggleClicked = this.onMenuToggleClicked.bind(this)
    this.state = {open: false}
  }

  componentDidMount() {
    this.props.items.map(item => {
      let image = new Image()
      image.src = item.background_image.url
      return image
    })
  }

	renderMenuItem = (item, index, location) => {
		//console.log(location, '  |  ', item);
		const { link, title } = item;
		const filteredLoc = this.getFilteredPath(location);
		const key = link.text + ' - ' + location;

		return (
			<MenuItem
				key={key}
				loc={location}
				filteredLoc={filteredLoc}
				active={link.text === this.getFilteredPath(location)}
				onClick={this.onMenuItemClicked(link.text, index)}
			>
				{title.text}
			</MenuItem>
		)
	}

	onMenuToggleClicked = () => {
		this.props.onMenuClicked()

		this.setState({ open: !this.state.open })
	}

	onMenuItemClicked = url => () => {
		this.setState({open: false})

		navigate(url)
	}

	setBackground = path => {
		const result = this.props.items.filter(
			item => item.link.text === this.getFilteredPath(path)
		)
		//console.log(result);

		if (result && result.length > 0) {
			return result[0].background_image.url
		}

		return this.props.items[0].background_image.url
	}

	getFilteredPath = pathname => {
		let split = pathname.split('/');
		split = split.filter(n => !!n);
		const path = split.length > 1 ? `/${split[1]}` : '/';
		//console.log(' === path:', path, '  == len:', split.length, '  === pathname:', pathname);
		return path;
	}

	render() {
		const { open } = this.state

		const { items, isDark, machineLabel, menuLabel } = this.props

		return (
			<Fragment>
				<MenuButton onClick={this.onMenuToggleClicked} isDark={isDark}>
				<HamburgerSqueeze
					buttonWidth={24}
					barColor={isDark ? '#000' : '#FFF'}
					buttonStyle={{ padding: '4px' }}
					isActive={open}
				/>
				<MenuLabel>{menuLabel}</MenuLabel>
				</MenuButton>
				<Location>
				{({ location: { pathname = '/' } }) => (
					<Overlay
						open={open}
						backgroundColour="#000"
						customClass="menuInnerContainer"
						>
						<MenuItemsContainer>
							{items.map((menuItem, index) =>
								this.renderMenuItem(menuItem, index, pathname)
							)}
						</MenuItemsContainer>
						<BackgroundImageContainer>
							<BackgroundImage src={this.setBackground(pathname)} />
						</BackgroundImageContainer>
					</Overlay>
				)}
				</Location>
			</Fragment>
		)
	}
}

Menu.defaultProps = {
  isDark: {
    isDark: false,
  },
}

export default Menu
