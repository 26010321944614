import React, { Fragment } from 'react';
import Cookies from 'universal-cookie';
import { PropTypes } from 'prop-types';
import {window} from 'browser-monads';

import {
  WhiteDropdown,
  WhiteDropdownWrapper,
  DropdownFlag,
  InlineDropdownOuter
} from '../../styles/contact';


class MiniLanguageSelectorUI extends React.Component {

	flags = {en:"🇬🇧", fr:"🇫🇷" , de:"🇩🇪", cn:"🇨🇳"};

	state = {
		flag: this.flags['en']
	}
	
	constructor(props) {
		super(props);
		this.onLanguageSelect = this.onLanguageSelect.bind(this);

		if(window){
			const loc = window.location.pathname;
			// const path = '/en/milk-systems';
			const lang = loc.split('/').filter(item => item.length > 0);

			if(lang && lang.length > 0){
				const f = lang[0].toLowerCase();
				this.state = {
					flag: this.flags[f]
				}
			}

		}
	}

	onLanguageSelect = ({ target }) => {
		if(target.value !== '') {
			this.setState({flag: this.flags[target.value.toLowerCase()]})
			setTimeout(() => {
				window.location = `${window.location.protocol}//${window.location.hostname}/${target.value.toLowerCase()}`;
			}, 500);
		}
	};

	renderSimpleDropdown = (countries) => (
		<InlineDropdownOuter>
			<DropdownFlag>{this.state.flag}</DropdownFlag>
			<WhiteDropdownWrapper>
				<WhiteDropdown onChange = { this.onLanguageSelect }>
				{ countries.map(country => this.renderOption(country)) }
				</WhiteDropdown>
			</WhiteDropdownWrapper>
		</InlineDropdownOuter>
	);

	renderOption = language => <option key = { language.locale.text } value = { language.locale.text }>{ language.language.text }</option>

	render() {
		const {
		languages
		} = this.props;

		return this.renderSimpleDropdown(languages)
	}
}

export default MiniLanguageSelectorUI;
