import React  from 'react';
import { PropTypes } from 'prop-types';
import SVG from 'react-inlinesvg';

import {
  getTagColour, getMilkSystem, getAccessories
} from '../../utils/selectors';

import {
	FilterContainer,
	Filters,
	IndividualFilter,
	FilterButton,
	Results,
	SearchBox,
	Label,
	FilterItemContainer,
	SearchContainer,
	FiltersContainer,
} from './styles';
import { Object } from 'window-or-global';

class Filter extends React.Component {
  static propTypes = {
	categories: PropTypes.instanceOf(Array).isRequired,
	items: PropTypes.instanceOf(Array).isRequired,
	itemRenderer: PropTypes.instanceOf(Object).isRequired,
  };

  static defaultProps = {
    	initialFilters: [],
  };

	constructor(props) {
		super(props);
		
		this.state = {
			activeFilters: null, //props.initialFilters,
			activeTag: '',
			activeRegion: '',
			activeCase: '',
			elements: this.props.items,
			regionsActive: false,
			casesActive: false,
		};

		// console.log(props.items)
	}

	componentDidMount() {
		this.filterElements();
	}


	toggleTag = (option) => {
		const opt = option.toLowerCase();
		const regionsActive = (opt == 'event' || opt == 'evénement' || opt == '活动');
		const casesActive = (opt == 'case study' || opt == 'etudes de cas' || opt == '案例分析' || opt == 'fallstudien');

		const activeTag = option == this.state.activeTag ? '' : option;

		this.setState({ activeTag, casesActive, regionsActive, activeCase: '', activeRegion: '' }, () => {
			this.filterElements();
		});
	}
	
	toggleRegion = (option) => {
		const activeRegion = option == this.state.activeRegion ? '' : option;
		this.setState({ activeRegion }, () => {
			this.filterElements();
		});
	}
	
	toggleCase = (option) => {
    	const activeCase = option == this.state.activeCase ? '' : option;
		// console.log(`🚀 ~ file: filter.jsx ~ line 80 ~ activeCase`, activeCase);
		this.setState({ activeCase }, () => {
			this.filterElements();
		});
	}


	filterElements = () => {
		const { items } = this.props;
		const { activeTag, activeRegion, activeCase, regionsActive, casesActive } = this.state;

		const hasFilters = activeTag != '';

		if(!hasFilters) {
			this.setState({ elements: items, casesActive: false, regionsActive : false });
			return;
		}

		let filter = [];

		// ALL ITEMS
		for (let i = 0; i < items.length; i++) {
			const item = items[i];
			// console.log(i, item.case_study_tag)
			if(item.tags && item.tags.indexOf(activeTag) >= 0) {
				filter.push(item)
			}
		}

		// EVENT ONLY
		if(regionsActive && activeRegion != '') {
			const local = [];
			for (let i = 0; i < filter.length; i++) {
				const item = filter[i];
				if(item.region && item.region.toLowerCase() == activeRegion.toLowerCase()) {
					local.push(item);
				}
			}

			filter = local;
		}
		
		// CASES ONLY
		if(casesActive && activeCase != '') {
			const local = [];
			for (let i = 0; i < filter.length; i++) {
				const item = filter[i];
				// console.log(i, activeCase, item.case_study_tag)
				if(item.case_study_tag && item.case_study_tag.indexOf(activeCase) >= 0) {
					local.push(item);
				}
			}

			filter = local;
		}
		

		this.setState({ elements: filter });
	};

	checkTagActive = (option) => {
		const { activeTag } = this.state;
		const active = activeTag == option;

		return active;
	};
	
	checkRegionActive = (option) => {
		const { activeRegion } = this.state;
		const active = activeRegion == option;

		return active;
	};
	
	checkCaseActive = (option) => {
		const { activeCase } = this.state;
		const active = activeCase == option;

		return active;
	};
	
	renderFilters = () => {
		// console.log('category :>> ', category);
		const {categories, regions, caseStudyTags} = this.props;
		const {regionsActive, casesActive} = this.state;

		const list = [];

		categories && categories.forEach((category, index) => {
			list.push(
				<IndividualFilter className={category.displayName} key={`${category.name}_filter_${index}`}>
				{
					category.options.map((option, index) => (
					<FilterButton
						key = { `${option.text}_tag_${index}` }
						onClick={ () => {this.toggleTag(option.text)} }
						value = { [ category.name, option.text ] }
						active = { this.checkTagActive(option.text) }
						className = { this.checkTagActive(option.text)  ? 'active' : '' }
						color = { getTagColour( option.text ) }
					>
						{ option.icon && <SVG src = { option.icon } /> }
						{ !category.iconOnly && option.text }
					</FilterButton>
					))
				}
				</IndividualFilter>
			)
		})

		if(regionsActive && regions && regions.length) {
			// console.log(regions)

			const regionsList = [];

			regions.forEach((region, index) => {
				regionsList.push(
					<FilterButton
						key = { `${region.text}_region_${index}` }
						onClick={ () => {this.toggleRegion(region.text)} }
						value = { [ 'region', region.text ] }
						active = { this.checkRegionActive(region.text) }
						className = { this.checkRegionActive(region.text)  ? 'active' : '' }
						color = { getTagColour( region.text ) }
					>
						{ region.text }
					</FilterButton>
				)
			})

			list.push(<IndividualFilter key={`regions_${list.length}`}> {regionsList} </IndividualFilter>)
		}
		if(casesActive && caseStudyTags && caseStudyTags.length) {
			// console.log(caseStudyTags)

			const casesList = [];

			caseStudyTags.forEach((case_study_tag, index) => {
				casesList.push(
					<FilterButton
						key = { `${case_study_tag.text}_cases_${index}` }
						onClick={ () => {this.toggleCase(case_study_tag.text)} }
						value = { [ 'case_study_tag', case_study_tag.text ] }
						active = { this.checkCaseActive(case_study_tag.text) }
						className = { this.checkCaseActive(case_study_tag.text)  ? 'active' : '' }
						color = { getTagColour( case_study_tag.text ) }
					>
						{ case_study_tag.text }
					</FilterButton>
				)
			})

			list.push(<IndividualFilter key={`cases_${list.length}`}> {casesList} </IndividualFilter>)
		}
		

		return list;
	};
	

	updateSearchBox = (evt) => {
		const searchTerm = evt.target.value.toLowerCase();
		const elements = this.state.elements;

		if(searchTerm === "") {
			this.filterElements();
			return;
		}

		const newElements = elements.filter(e => {
			return e.searchableTags.filter(elem => elem.toLowerCase().includes(searchTerm)).length > 0;
		});

		this.setState({ elements: newElements })
	};

  render() {
    const {
      categories,
      itemRenderer,
      customFilterClasses,
      customResultsClasses,
      customFiltersContainerClasses,
      hideSearch,
      onItemClicked,
      searchBoxLabel,
      filterLabel,
      searchLabel,
    } = this.props;

	const { eventsActive } = this.state;
	const filterClasses = eventsActive ? `${customFilterClasses} eventsActive` : `${customFilterClasses}`;

    return (
      <FilterContainer className = { filterClasses }>
        <Filters>
          <FiltersContainer className = { customFiltersContainerClasses }>
            { !hideSearch && <Label>{ filterLabel }</Label> }
            
			{
				<FilterItemContainer className="filterCategory">
					{/* { category.displayName !== undefined && category.displayName !== '' && <p>{ category.displayName }</p> } */}
					{this.renderFilters()}
				</FilterItemContainer>
			}

          </FiltersContainer>
          <SearchContainer>
            { !hideSearch && <Label>{ searchBoxLabel }</Label> }
            { !hideSearch && <SearchBox placeholder = { searchLabel } onChange = { evt => this.updateSearchBox(evt) } /> }
          </SearchContainer>
        </Filters>
        <Results className = { customResultsClasses }>
          {
            this.state.elements.map(item => React.createElement(itemRenderer, { ...item, handleClick: onItemClicked, key: `item_${item.title}_${Math.random()}` }))
          }
        </Results>
      </FilterContainer>
    );
  }
}

export default Filter;
