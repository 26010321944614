import React, { Fragment } from 'react'
import Cookies from 'universal-cookie'
import { PropTypes } from 'prop-types'
import {window} from 'browser-monads';

import {
	LanguageSelectorContainer,
	Container,
	LeftContainer,
	RightContainer,
} from './styles'

import { DropdownWrapper, SelectInput } from '../contact-form/styles'

import {
	DropdownOuter,
	WhiteDropdown,
	WhiteDropdownWrapper,
	InlineDropdownOuter,
} from '../../styles/contact'

class CountrySelectorUI extends React.Component {
	constructor(props) {
		super(props)

		const cookies = new Cookies()

		this.onLanguageSelect = this.onLanguageSelect.bind(this)

		this.state = {
			visible: true,
			cookies: cookies,
		}
	}

	UNSAFE_componentWillMount() {
		const { cookies } = this.state
		const {
			setCountry,
			setCountryList,
			countries,
			languages,
			simpleUi,
			onSelectionMade,
		} = this.props

		// console.log(countries);
		// console.log(languages);

		const cookie = cookies.get('ev-country')

		if (!simpleUi) {
			setCountryList(countries)
		}

		if (cookie) {
			this.setState({visible: false});

			const country = countries.filter(country => country.country_code.text.toLowerCase() === cookie.toLowerCase() )[0]
			if (country) {
				setCountry(country)
				onSelectionMade()
			}
		}
	}

	onLanguageSelect = ({ target }) => {
		if(!target || !target.value || target.value == '') return;

		//console.log(target.value)
		const { cookies } = this.state;
		const { countries, languages } = this.props;

		if(target.value !== '') {
			// cookies.set('ev-country', target.value, { path: '/', domain:'localhost:8000' });
			cookies.set('ev-country', target.value, { path: '/', domain:'eversys.com' });
			cookies.set('ev-country', target.value, { path: '/', domain:'dev.eversys.nrthpoint.com' });
			cookies.set('ev-country', target.value, { path: '/', domain:'eversys.nrthpoint.com' });

			//const country = countries.filter(country => country.country_code.text.toLowerCase() === target.value.toLowerCase())[0]
			const country = target.value.toLowerCase();

			setTimeout(() => {
				window.location = `${window.location.protocol}//${window.location.hostname}/${country}`;
			}, 500);
		}
	}

	renderSimpleDropdown = countries => (
		<InlineDropdownOuter>
			<WhiteDropdownWrapper>
				<WhiteDropdown onChange={this.onLanguageSelect}>
				{countries.map(country => this.renderOption(country))}
				</WhiteDropdown>
			</WhiteDropdownWrapper>
		</InlineDropdownOuter>
	)

	renderDropdown = languages => (
		<DropdownOuter>
			<DropdownWrapper>
				<SelectInput onChange={this.onLanguageSelect}>
					{this.renderOption({locale:{text:''}, language:{text:'Select'}})}
					{languages.map(item => this.renderOption(item))}
				</SelectInput>
			</DropdownWrapper>
		</DropdownOuter>
	)

	renderOption = language => (
		<option key={language.locale.text} value={language.locale.text}>
		{language.language.text}
		</option>
	)
	// renderOption = country => (
	// 	<option key={country.country_code.text} value={country.country_code.text}>
	// 	{country.country.text}
	// 	</option>
	// )

	render() {
		const { countries, languages, label, simpleUi } = this.props

		const { visible } = this.state

		return visible && !simpleUi ? (
			<LanguageSelectorContainer className="language-selector">
				<Container>
				<LeftContainer>
					<p>{label}</p>
				</LeftContainer>
				<RightContainer>{this.renderDropdown(languages)}</RightContainer>
				</Container>
			</LanguageSelectorContainer>
		) : simpleUi ? (
			this.renderSimpleDropdown(countries)
		) : null
	}
}

export default CountrySelectorUI
