import React from 'react';
import styled from 'styled-components'
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const OuterMachineCardContainer = styled.div`
  width: calc(50% - 15px);
  display: inline-block;
  cursor: pointer;
  
  &:nth-child(odd) {
    margin-right: 15px;
  }
  
  ${breakpoint('tablet')`
     width: calc(25% - 25px);
     margin-right: 25px;
     margin-bottom: 25px;
     
     &:nth-child(odd) {
       margin-right: 25px;
     }
  `}
`;

export const MachineCardContainer = styled.div`
  background: #EEE;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 250px;
  margin-top: 10px;
  background-image: url(${props => props.image});
  
  ${breakpoint('tablet')`
    height: 40vh;
  `};
`;

export const MachineName = styled.h4`
  font-family: "Brown-Pro-Bold", AdobeBlank, serif;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  color: #000;
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 13px;

  ${breakpoint('tablet')`
	font-size: 18px;
  `}

`;
