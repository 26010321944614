import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';


export const OverviewContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	/* justify-content: center; */
	padding: 0 30px;

	${breakpoint('tablet')`
		margin-right: 30px;
	`};
`;

export const RangeContainer = styled.div`
	display: flex;
	/* justify-content: center; */
	flex-wrap: wrap;
	padding: 0 30px;

	${breakpoint('tablet')`
		margin-right: 30px;
	`};
`;


export const FiltersContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 15px;
	padding-left: 15px;
	/* visibility: hidden; */

	/* ${({ visible }) => visible && `
		visibility: visible;
	`} */

	${breakpoint('tablet')`
		flex-direction: row;
		padding-right: 30px;
		padding-left: 30px;
	`};
`;

export const FilterGroup = styled.div`
	margin-right: 50px;
`;

export const FilterLabel = styled.p`
	font-size: 14px;
	/* margin-bottom: 10px; */
`;

export const FilterButtonsGroup = styled.p`
	display: flex;
`;


export const FilterButton = styled.button`
	padding: 0 12px !important;
	border: 1px solid rgb(230, 230, 230);
	height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	outline: none;

	svg {
		width: auto !important;
	}

	svg g {
		fill: #000;
	}

	&:not(:first-child):not(:last-child) {
		border-left: none;
		border-right: none;
	}

	&:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
	}
	&:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	svg {
		margin-right: 5px;
	}

	${({ active }) => active && `
		border: 1px solid #666;
		background: #000;
		color: #FFF;
		
		svg g, svg g path {
			fill: #FFF !important;
		}
	`}

`;



export const BackToAll = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	height: 86px;
	padding: 30px;
	z-index: 99;
	z-index: 999;

	svg {
		display: block;
		margin-right: 10px;
		margin-top: -1px;
	}

	p {
		display: none;
		font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
		font-weight: 300;
		font-size: 13px;
		letter-spacing: 3px;
		text-transform: uppercase;
		pointer-events: none;

		${breakpoint('tablet')`
			display: inline-block;
		`};
	}
`;