import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'

import Header from '../components/header/index'
import MetaTags from '../components/metaTags/index'
import Footer from '../components/footer/footer'
import CountrySelector from '../components/country-selector/index'

import { BodyStyles, Preloader } from '../styles/eversys'
import '../styles/fonts.scss'
import '../styles/typeography.scss'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.onPageLoad = this.onPageLoad.bind(this)

    this.state = {
      loaded: false,
      countrySelectorOpen: true,
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined' && window) {
      //console.log('adding')
      window.addEventListener('load', this.onPageLoad)
      document.addEventListener('load', this.onPageLoad)
    }

    this.setState({
      loaded: true,
    })
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined' && window) {
      window.removeEventListener('load', this.onPageLoad)
    }
  }

  onCountrySelected = () => {
    this.setState({
      countrySelectorOpen: false,
    })
  }

  onPageLoad = () => {
    this.setState({
      loaded: true,
    })
  }

  render() {
    const { loaded, countrySelectorOpen } = this.state

    const { children, isWhite, customClassName } = this.props

    let className = customClassName

    if (countrySelectorOpen) className += ' languageActive'

    return (
      <Fragment>
        <MetaTags />
        <BodyStyles whiteColor={isWhite} />
        <CountrySelector
          simpleUi={false}
          onSelectionMade={this.onCountrySelected}
        />
        <Header isWhite={isWhite} />
        <main className={className}>
          <Preloader hide={loaded} />
          {children}
        </main>
        <Footer />
      </Fragment>
    )
  }
}

Layout.defaultProps = {
  isWhite: false,
  customClassName: '',
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  customClassName: PropTypes.string,
  isWhite: PropTypes.bool,
}

export default Layout
