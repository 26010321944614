import React, { Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Menu from './menu';

const Index = ({ isDark, onMenuClicked }) => (
  <Fragment>
    <StaticQuery
      query = { query }
      render = { data => (
        <Menu
          menuLabel = { data.allPrismicMenu.edges[ 0 ].node.data.button_label.text }
          machineLabel = { data.allPrismicMenu.edges[ 0 ].node.data.machine_navigation_label.text }
          items = { data.allPrismicMenu.edges[ 0 ].node.data.body[ 0 ].items }
          isDark = { isDark }
          onMenuClicked = { onMenuClicked }
        />
) }
    />
  </Fragment>
);

const query = graphql`
    query GetMenuItems {
        allPrismicMenu {
            edges {
                node {
                    data {
                        button_label {
                            text
                        }
                        machine_navigation_label {
                            text
                        }
                        machine_filter_one_label {
                            text
                        }
                        machine_filter_two_label {
                            text
                        }
                        body {
                            items {
                                background_image {
                                    url
                                }
                                title {
                                    text
                                }
                                link {
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Index;
