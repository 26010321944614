import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';

export const OverlayContainer = styled.section`
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image});
  
  opacity: 0;
  transition: all .4s ease-in-out;
  
  //overflow-y: scroll;
  
  &.active {
    opacity: 1;
  }
  
  &:after {
    position:absolute;
    width: 0;
    height: 0;
    content: url(${props => props.image});
    
    overflow:hidden; 
    z-index:-1;
  }
`;

export const InnerContainer = styled.section`
  //position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  
  opacity: 0;
  transition: all .4s ease-in-out .4s;
  
  &.active {
    opacity: 1;
  }
  
  overflow-x: scroll;
  height: 100vh;
  padding-bottom: 170px;
  margin-top: 80px;
  -webkit-overflow-scrolling: touch;
`;
