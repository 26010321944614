import React, { Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import MiniLanguageSelectorUI from './mini-language-selector';

const MiniLanguageSelector = () => (
  <Fragment>
    <StaticQuery
      query = { query }
      render = { data => (
        <MiniLanguageSelectorUI
          languages = { data.allPrismicFooterCountries.edges[0].node.data.body[0].items }
        />
      ) }
    />
  </Fragment>
);

const query = graphql`
    query GetFooterLanguages {
        allPrismicFooterCountries {
            edges {
                node {
                    data {
                        body {
                            items {
                                language {
                                    text
                                }
                                locale {
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default MiniLanguageSelector;
