import React from 'react';
import { navigate } from 'gatsby';

import {
  OuterMachineCardContainer,
  MachineCardContainer,
  MachineName
} from './styles'
import { MachineOverview } from '../machine-card/styles';

const MachineLine = ({data, name, uid, title, navImage, isPlaceholder, handleClick = () => {} }) => (
	<OuterMachineCardContainer
		key={ `${name}-${uid}` }
		onClick={() => {
			// if(isPlaceholder) {
			// 	return;
			// }	
			handleClick(data);
		}}
	>
		<MachineCardContainer image = { navImage }>
			<MachineOverview>{ title }</MachineOverview>
			<MachineName>{ name }</MachineName>
		</MachineCardContainer>
	</OuterMachineCardContainer>
);

export default MachineLine;
