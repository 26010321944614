import React from 'react';
import { navigate } from 'gatsby';

import {
  OuterMachineCardContainer,
  MachineCardContainer,
  MachineName
} from './styles'

const MachineOverview = ({data, name, uid, navImage, handleClick = () => {} }) => (
  <OuterMachineCardContainer key = { `${name}-${uid}` } onClick = { () => { handleClick(data); } }>
    <MachineCardContainer image = { navImage }>
      <MachineName>{ name }</MachineName>
    </MachineCardContainer>
  </OuterMachineCardContainer>
);

export default MachineOverview;
