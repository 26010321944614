import React from 'react';
import styled from 'styled-components'
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';
import { DESKTOP_MAX_WIDTH } from '../../styles/variables';

export const LanguageSelectorContainer = styled.article`
  width: 100%;
  background: #F0F0F0;
  padding: 10px 20px;
`;

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftContainer = styled.div`
  flex: 3;
`;

export const RightContainer = styled.div`
  flex: 1;
  
  span {
    padding: 0;
    margin: 0;
  }
`;
