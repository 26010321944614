import React from 'react';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const MenuItemsContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  ${breakpoint('tablet')`
    flex-direction: row;
  `};
`;

export const MenuItem = /*withReveal(*/styled.button`
  font-size: 20px;
  color: #FFF;
  padding: 30px 20px;
  position: relative;
  width: 70%;
  text-align: left;
  font-family: "Brown-Pro-Light", AdobeBlank, Serif;
  font-weight: 300;
  
  ${({ active }) => active && `
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.5+0,0+100 */
    background: -moz-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0,0,0,0.5) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
  `}
  
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 20%;
    bottom: 0;
    height: 1px;
    background: #FFF;
    opacity: .5;
  }
  
  ${breakpoint('tablet')`
    flex: 1;
    padding-top: 80vh;
    padding-left: 30px;
    font-size: 30px;
    font-weight: 300;
    cursor: pointer;
    
    &:after {
      left: 0;
      top: 80%;
      bottom: 0;
      width: 1px;
      height: auto;
    }
    
    ${({ active }) => active && `
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.5+0,0+100 */
      background: -moz-linear-gradient(bottom, rgba(0,0,0,0.5) 0%, rgba(125,185,232,0) 80%); /* FF3.6-15 */
      background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5) 0%,rgba(125,185,232,0) 80%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to top, rgba(0,0,0,0.5) 0%,rgba(125,185,232,0) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
    `}
    
    &:hover {
      background: -moz-linear-gradient(bottom, rgba(0,0,0,0.5) 0%, rgba(125,185,232,0) 80%); /* FF3.6-15 */
      background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5) 0%,rgba(125,185,232,0) 80%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to top, rgba(0,0,0,0.5) 0%,rgba(125,185,232,0) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
    }
  `};
`/*, <Fade />)*/;

export const BackgroundImageContainer = styled.span`
   z-index: -1;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
`;

export const BackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit:cover;
`;
