import React, { Fragment } from 'react'
import { navigate } from 'gatsby-link';
import { ThemeProvider } from 'styled-components';
import SVG from 'react-inlinesvg';
import {window} from 'browser-monads';
import {
  HeaderContainer,
  CentralHeaderItem,
  SideMenuItem,
  MenuButton,
  LightMenuTheme,
  DarkMenuTheme,
  MenuButtonDesktop,
  HeaderLogoMobile,
  HeaderLogoDesktop,
  BackToAll
} from './styles';

import Menu from '../menu/index';
import MachineNavigation from '../machine-navigation';

class Header extends React.Component {
  constructor(props) {
	super(props);
	
	// console.log(this.props.machineAllLabel);

    this.onMenuToggleClicked = this.onMenuToggleClicked.bind(this);
    this.onMachineToggleClicked = this.onMachineToggleClicked.bind(this);
    // this.onMachinesBackTo = this.onMachinesBackTo.bind(this);

    this.state = {
      menuOpen: false,
	  machinesOpen: false,
	  machinesBackTo: null,
      theme: props.isWhite ? DarkMenuTheme : LightMenuTheme,
    };
  }

  onMenuToggleClicked() {
	this.setState({
      menuOpen: !this.state.menuOpen,
      machinesOpen: false,
    });
  }

  onMachineToggleClicked() {
		if(this.state.machinesOpen){
			this.setState({machinesBackTo:null});
		};

		this.setState({
			menuOpen: false,
			machinesOpen: !this.state.machinesOpen,
			theme: this.state.theme === DarkMenuTheme ? LightMenuTheme : DarkMenuTheme,
		});
  }

//   onMachinesBackTo(machinesBackTo) {
// 		// console.log("Header -> onMachinesBackTo -> machinesBackTo", machinesBackTo)
// 		this.setState({machinesBackTo})
//   }

// 	onArrowBackTo() {
// 		console.log("Header -> onArrowBackTo:", this.state.machinesBackTo)

// 		if(this.state.machinesBackTo === 'line') {
//             this.setState({machinesBackTo: 'overview'})
// 		} else {
// 			this.setState({machinesBackTo: null})
// 		}
// 	}

  isChina() {
	  if(!window || !window.location) return false;
	  return window.location.pathname.indexOf('/cn/') >= 0;
  }

  render() {
    const {
      theme,
      menuOpen,
	  machinesOpen,
	  machinesBackTo
    } = this.state;

    let {
      isWhite,
    } = this.props;

    const isDark = menuOpen ? false : (isWhite || theme === DarkMenuTheme);
	//console.log('isDark:', isDark, '  |  open:', menuOpen, '  |  white:', isWhite, '  |  theme',  theme === DarkMenuTheme);
	
	// const getArrowLabel = () => {
	// 	if(this.state.machinesBackTo === 'overview') return this.props.machineBackToRangeLabel;
	// 	if(this.state.machinesBackTo === 'line') return this.props.machineBackToSeriesLabel;

	// 	return null;
	// }

    return (
      <HeaderContainer isWhite = { isWhite || theme === DarkMenuTheme }>
        <ThemeProvider theme = { theme }>
          <Fragment>
            <SideMenuItem direction="left" style={machinesOpen ? {pointerEvents:'none'} : null}>
              { !machinesOpen && <Menu isDark = { isDark } onMenuClicked = { this.onMenuToggleClicked } /> }
			  {/* {machinesBackTo &&
				<BackToAll onClick={()=>{this.onArrowBackTo}}>
					<SVG src={'/images/machine-all-arrow.svg'} />
					<p>{getArrowLabel()}</p>
				</BackToAll>
			  } */}
            </SideMenuItem>
            <CentralHeaderItem>
              	<HeaderLogoDesktop isDark = { isDark } forceWhite = { menuOpen } onClick = { () => navigate('/') }>
				  	{!this.isChina() && <SVG src = "/images/EVERSYS_brand_logo_White.svg" />}
				  	{this.isChina() && <SVG src = "/images/Eversys_logo_china.svg" />}
				</HeaderLogoDesktop>
              <HeaderLogoMobile isDark = { isDark } forceWhite = { menuOpen } onClick = { () => navigate('/') }><SVG src = "/images/eWhite.svg" /></HeaderLogoMobile>
            </CentralHeaderItem>
            <SideMenuItem direction = "right" isDark = { isDark }>
			  {<MachineNavigation
					onMenuOpened={this.onMachineToggleClicked}
					machinesOpen={machinesOpen}
					machineBackToRangeLabel={this.props.machineBackToRangeLabel}
					machineBackToSeriesLabel={this.props.machineBackToSeriesLabel}
					onItemClicked={()=>this.setState({ machinesOpen: false })}
			  />}
            </SideMenuItem>
          </Fragment>
        </ThemeProvider>
      </HeaderContainer>
    )
  }
}

Header.defaultProps = {
  isWhite: false,
};

export default Header;
