import React, { Fragment } from 'react'
import { StaticQuery, graphql } from "gatsby"
import Header from './header'

const Index = ({ isWhite }) => (
  <Fragment>
    <StaticQuery
      query = { query }
      render = { data => (
        <Header
		  machineAllLabel={data.allPrismicMenu.edges[0].node.data.machine_menu_show_all_label.text}
		  machineBackToSeriesLabel={data.allPrismicMenu.edges[0].node.data.back_to_series.text}
		  machineBackToRangeLabel={data.allPrismicMenu.edges[0].node.data.bak_to_range.text}
		  machineAllLabel={data.allPrismicMenu.edges[0].node.data.machine_menu_show_all_label.text}
          siteTitle = { data.allPrismicGlobalSiteHeader.edges[0].node.data.site_title.text }
          isWhite = { isWhite }
        />
) }
    />
  </Fragment>
);

const query = graphql`
query GlobalSiteHeaderQuery {
  allPrismicGlobalSiteHeader {
      edges {
        node {
          data {
            site_title {
              html
              text
            }
          }
        }
      }
    }
	allPrismicMenu {
      edges {
        node {
          data {
            machine_menu_show_all_label {
              text
            }
            back_to_series {
              text
            }
            bak_to_range {
              text
            }
          }
        }
      }
    }
}
`;
export default Index
