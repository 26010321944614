import React, { Fragment } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import MetaTags from './metaTags'

const MetaTagsContainer = () => (
  <Fragment>
    <StaticQuery
      query = { query }
      render = { data => (
        <MetaTags
          siteTitle = { data.allPrismicSeoMetaTags.edges[0].node.data.site_title.text }
          siteDescription = { data.allPrismicSeoMetaTags.edges[0].node.data.site_description.text }
          siteKeywords = { data.allPrismicSeoMetaTags.edges[0].node.data.keywords.text }
        />
) }
    />
  </Fragment>
);

const query = graphql`
query GlobalMetaTagsQuery {
  allPrismicSeoMetaTags {
    edges {
			node {
        data {
          site_title {
            text
          }
          site_description {
            text
          }
          keywords {
            text
          }
        }
      }
    }
  }
}
`

export default MetaTagsContainer
