import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const FooterContainer = styled.footer`
  background: #000000;
  padding: 30px 20px;

  p {
    color: #fff;
    text-align: center;
  }

  ${breakpoint('tablet')`
    padding: 30px 20px 0;
  `};
`

export const Columns = styled.div`
	border-top: 1px solid #333;
	padding: 50px 20px;
	display: flex;
	flex-direction: column;

	${breakpoint('tablet')`
		flex-direction: row;
	`};
`;

export const LinksOutter = styled.div`
	flex: 4;
	display: flex;
	justify-content: flex-start;

	flex-direction: column;

	${breakpoint('tablet')`
		flex-direction: row;
		// justify-content: space-around;
	`};
`;

export const ColSubscribe = styled.div`
	flex: 2;
	display: flex;
	flex-direction: column;

	${breakpoint('tablet')`
	`};
`;

export const LinkCol = styled.div`
	width: 100%;
	flex: 1;
	
	${breakpoint('tablet')`
		flex: initial;
		width: auto;
		flex: 1;
		// margin-right: 10%;
	`};

	strong{
		font-weight: 500;
		font-family: "Brown-Pro-Bold";
		font-size: 13px;
	}

	p, a, a:visited, a:active, a:hover{
		color: #FFF;
		text-decoration: none;
		font-size: 12px;
		line-height: 12px;
		font-weight: 300;

		text-align: center;

		${breakpoint('tablet')`
			text-align: left;
		`};
	}
`;

export const AddressCol = styled.div`
	flex: 1;
	width: 290px;

	text-align: center;

	${breakpoint('tablet')`
		width: auto;
		text-align: left;
	`};

	p, a, a:visited, a:active, a:hover{
		color: #FFF;
		text-decoration: none;
		font-size: 12px;
		line-height: 18px;
		font-weight: 300;
		margin-top: 0;

		text-align: center;

		${breakpoint('tablet')`
			text-align: left;
		`};
	}
`;

export const AddressPhone = styled.div`
	font-family: "Brown-Pro-Regular";
	color: #FFF;
	text-decoration: none;
	font-size: 12px;
	line-height: 18px;
	font-weight: 300;
	margin-top: 0;

	text-align: center;

	${breakpoint('tablet')`
		text-align: left;
	`};
`;

export const AddressText = styled.div`
	

	p, a, a:visited, a:active, a:hover{
		color: #FFF;
		text-decoration: none;
		font-size: 12px;
		
		font-weight: 300;

		text-align: center;

		${breakpoint('tablet')`
			text-align: left;
		`};
	}
`;


export const InnerContainer = styled.div`
  ${breakpoint('tablet')`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 50px 20px;
  `};
`

export const LogoContainer = styled.div`
  img {
    width: 100px;
    margin: auto;
  }

  svg g, svg path {
    fill: #FFFFFF;
  }

  ${breakpoint('tablet')`
    img {
      max-width: 200px;
      width: 200px;
    }
  `};
`

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;

  ${breakpoint('tablet')`
    order: 1;
    flex: 1;
    display: flex;
    justify-content: flex-start;
  `};
`

export const Signature = styled.div`
  span {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }

  svg {
    max-width: 400px;
    margin: auto;
    width: 90%;
  }

  ${breakpoint('tablet')`
    order: 2;
    flex: 1;
  `};
`

export const Copyright = styled.span`
	display: block;
	width: 100%;
	text-transform: uppercase;
	text-align: center;
	color: #CBC8CB;
	font-size: 10px;
	margin-top: 30px;

	${breakpoint('tablet')`
		display: inline;
		text-align: left;
		margin-top: 0;
	`};
`

export const SocialLinksContainer = styled.div`
  padding: 20px 0;

  ${breakpoint('tablet')`
    order: 3;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  `};
`

export const SocialLinksInner = styled.div`
  ${breakpoint('tablet')`
    display: inline-block;
   
    p {
      text-align: left;
      margin: 0 0 0 10px;
    }
  `};
`

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SocialLink = styled.a`
  width: 50px;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  img {
    max-width: 100%;
  }

  svg {
    width: 25px !important;
  }
`

export const Links = styled.div`
	margin-top: 0;
	padding: 0;
	height: 1px;
	border-bottom: 1px solid #333;

	a {
		font-family: 'Brown-Pro-Regular', AdobeBlank, Serif;
		color: #fff;
		font-size: 14px;
		display: block;
		text-decoration: none;
		padding: 10px 0;
		text-align: center;
	}

	${breakpoint('tablet')`
		a {
			display: inline-block;
			margin-right: 15px;
			font-size: 11px;
		}
		
		display: flex;
		align-items: center;
		margin-top: 20px;
		height: 80px;
		border-top: 1px solid #333;
		padding: 20px;
		border-bottom: 0;
	`}
`

export const LanguageSelectorContainer = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #333;
  display: flex;
  align-items: center;
  justify-content: center;

  select {
    width: 300px;
    margin: 0 auto;
  }
`

export const SecondaryLinks = styled.span`
  float: right;
  display: flex;
  margin-top: -1px;

  p {
    margin-top: 9px;
  }
`

export const QRCodeContainer = styled.div`
  text-align: center;
`

export const QRCodeContainerLink = styled.a`
  text-align: center;
`

export const QRCode = styled.img`
  max-width: 150px;
  margin: 0 auto 20px;

  ${breakpoint('tablet')`
    margin: 0 auto;
  `}
`
