import React, { Fragment } from 'react'

import { OverlayContainer, InnerContainer } from './styles';

class Overlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showContent: false,
    };
  }

  componentWillReceiveProps(nextProps, nextState) {
    const currentOpen = this.props.open;
    const nextOpen = nextProps.open;

    if(nextOpen && currentOpen !== nextOpen) {
      document.body.classList.add('overlay-open');
      setTimeout(() => {
        this.setState({
          showContent: true
        });
      }, 100);
    }

    if(!nextOpen) {
      document.body.classList.remove('overlay-open');
      this.setState({
        showContent: false
      });
    }
  }

  render() {
    const {
      open,
      backgroundColour,
      backgroundImage,
      children,
      useInternalScrolling,
      customClass,
    } = this.props;

    typeof document !== 'undefined' && document.body.classList[open ? 'add' : 'remove']('no-scroll');
    const classes = this.state.showContent ? `active ${customClass}` : customClass;

    return open ? (
      <OverlayContainer image = { backgroundImage } style = { { backgroundColor: backgroundColour } } className = { classes }>
        <InnerContainer className = { classes }>{ children }</InnerContainer>
      </OverlayContainer>
    ) : null;
  }
}

export default Overlay;
