import React from 'react';
import { navigate } from 'gatsby';

import {
  OuterMachineCardContainer,
  MachineCardContainer,
  MachineName,
  MachineOverview
} from './styles'

const MachineCard = ({ data, name, url, title, navImage, isPlaceholder, handleClick = () => {} }) => (
	<OuterMachineCardContainer key={ `${name}-${url}` }
		onClick={()=>{
			handleClick(data);
			!isPlaceholder && navigate('/machines/' + url);
		}
	}>
		<MachineCardContainer image={ navImage }>
			<MachineOverview>{ title }</MachineOverview>
			<MachineName>{ name }</MachineName>
		</MachineCardContainer>
	</OuterMachineCardContainer>
);

export default MachineCard;
