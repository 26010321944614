import React, { Fragment, useState } from 'react'
import { graphql, navigate, StaticQuery } from 'gatsby'

import {
  FormContainer,
  InnerContainer,
  TextInput,
  SelectInput,
  FormPrivacy,
  FormTitle,
  InputContainer,
  TextArea,
  Button,
} from './styles'
import { useRef } from 'react'
import { useEffect } from 'react'

const SubscribeFooterUI = ({
  heading,
  description,
  name,
  email,
  submit,
  success_message,
  error_message,
  introduction_footer,
  footer_policy,
}) => {
  return (
    <FormContainer>
      <FormTitle>{introduction_footer}</FormTitle>
    </FormContainer>
  )
}

const SubscribeFooter = (data) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '25275903',
          formId: '5b2e7b3b-d524-4980-bbbc-692039849608',
          target: '#hubspotForm',
        })
      }
    })
  }, [])

  return (
    <FormContainer>
      {/* <StaticQuery
        query={query}
        render={(data) => (
          <FormTitle>
            {
              data.allPrismicSubscriptionForm.edges[0].node.data
                .introduction_footer.text
            }
          </FormTitle>
        )}
      /> */}

      <div id="hubspotForm"></div>
    </FormContainer>
  )
}

// const query = graphql`
//     query SubscribeFooterQuery {
//         allPrismicSubscriptionForm {
//             edges {
//                 node {
//                     data {
//                         title {
//                             text
//                         }
//                         introduction {
//                             text
//                         }
//                         name_label {
//                             text
//                         }
//                         email_label {
//                             text
//                         }
//                         submit_label {
//                             text
//                         }
//                         introduction_footer {
//                           text
//                         }
//                         footer_policy {
//                           text
//                         }
//                         error_message
//                         success_message
//                     }
//                 }
//             }
//         }
//     }
// `;

export default SubscribeFooter
