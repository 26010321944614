import { connect } from 'react-redux';
import React, { Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { setCountry, setCountryList } from '../../store/actions';
import CountrySelectorUI from './country-selector';
import { LanguageSelectorContainer } from '../footer/styles';
import MiniLanguageSelector from '../mini-language-selector';

const mapStateToProps = state => ({
  country: state.country,
});

const mapDispatchToProps = dispatch => ({
  setCountry: country => dispatch(setCountry(country)),
  setCountryList: countryList => dispatch(setCountryList(countryList))
});

const ConnectedCountrySelectorUI = connect(mapStateToProps, mapDispatchToProps)(CountrySelectorUI);

const CountrySelector = ({ simpleUi, onSelectionMade }) => (
	<Fragment>
		<StaticQuery
			query = { query }
			render = { data => (
				<ConnectedCountrySelectorUI
					languages = { data.allPrismicFooterCountries.edges[0].node.data.body[0].items }
					countries = { data.allPrismicLanguages.edges[0].node.data.body[0].items }
					label = {  data.allPrismicLanguages.edges[0].node.data.banner_introduction.text  }
					simpleUi = { simpleUi }
					onSelectionMade = { onSelectionMade }
					/>
			) }
		/>
	</Fragment>
);

const query = graphql`
    query GetLanguages {
        allPrismicLanguages {
            edges {
                node {
                    data {
                        banner_introduction {
                            text
                        }
                        body {
                            items {
                                country {
                                    text
                                }
                                country_code {
                                    text
                                }
                                redirect_to {
                                    url
                                }
                                language {
                                    text
                                }
                                contact_email {
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }

		allPrismicFooterCountries {
            edges {
                node {
                    data {
                        body {
                            items {
                                language {
                                    text
                                }
                                locale {
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }

    }
`;

export default CountrySelector;
