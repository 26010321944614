import React from 'react';
import breakpoint from 'styled-components-breakpoint'
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';

export const FormContainer = styled.form`
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  
  button {
    text-align: center;
    display: block;
    margin: 20px auto;
  }
`;

export const FormRow = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 10px;
	
	${breakpoint('tablet')`
		grid-template-columns: 1fr 1fr;
	`};
`; 

export const FormRow2 = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 10px;

	${breakpoint('tablet')`
		grid-template-columns: 1fr 1fr 1fr;
	`};
`; 

export const SubmitRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint('tablet')`
		flex-direction: row;
	`};

  button {
    align-self: flex-start;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;

    ${breakpoint('tablet')`
      flex-direction: row;
      margin-top: 25px;
      margin-bottom: 30px;
      width: auto;
    `};
  }
`; 

export const Checkbox = styled.div`
  cursor: pointer;
  position: relative;
  padding-right: 0;
  padding-top: 20px;

  ${breakpoint('tablet')`
    padding-right: 20px;
  `};

  input {
    position: absolute;
    top: 20px;
    left: 0;
  }

  div {
    padding-left: 30px;
  }
	
  a, p {
    padding: 0;
    margin: 0;
    color: #000000 !important;
    font-size: 10px;
    line-height: 15px;
    font-weight: 300;
  }
`;

export const TextInput = styled.input`
  display: block;
  margin: 10px 0;
  padding: 10px 8px;
  width: calc(100%);
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  background: #F0F0F0;
  font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
  color: #1d1d1d;
      
  &::placeholder {
    color: #1d1d1d;
  }
`;

export const SelectInput = styled.select`
  display: block;
  -moz-appearance: none; 
  -webkit-appearance: none; 
  appearance: none;
    margin: 10px 0;
    padding: 10px 6px;
    width: calc(100%);
    border: 1px solid #c6c6c6;
    border-radius: 4px;
        background: #F0F0F0;
          font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
                color: #1d1d1d;
                
                option {
               
                      color: #1d1d1d;
                }

`;

export const TextArea = styled.textarea`
  display: block;
    margin: 10px 0;
    padding: 10px 8px;
    width: calc(100%);
    border: 1px solid #c6c6c6;
    border-radius: 4px;
        background: #F0F0F0;
          font-family: "Brown-Pro-Regular", AdobeBlank, Serif;
                color: #1d1d1d;

        &::placeholder {
            color: #1d1d1d;

      }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  
  &:after {
    content: ' ';
    background: url('/images/dropDown.svg') no-repeat center;
    width: 10px;
    height: 10px;
    background-size: cover;
    position: absolute;
    top: 50%;
    right: 13px;
    margin-top: -5px;
    pointer-events: none;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
`;

export const SuccessMessage = styled.p`
  color: #95C11F;
  font-size: 14px;
  margin-top: 25px;
  margin-bottom: 5px;
`;